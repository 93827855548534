export const Styles = {
  MainHeadingWeight: undefined,
  MainHeadingStyle: undefined,

  SubHeadingWeight: undefined,
  SubHeadingStyle: undefined,

  DefaultHeadingWeight: 400,
  DefaultHeadingStyle: undefined,
} as const;
