import type { ColorDefinition } from './color-types';

const ClassicPalette = {
  // Background
  Silk: '#f3f4f1',

  // Primary Colors
  Green: '#25ac61',
  GreenLight: '#3abd74',
  GreenHighlight: '#47c77a',

  // Alternate Colors
  Red: '#f04149',
  RedLight: '#ff5f60',
  RedHighlight: '#fc7680',

  // Neutral Colors
  Black: '#222829',
  Dark: '#4b5253',
  Darker: '#343b3c',
  Light: '#e0e4e3',
  Lighter: '#c7cbcb',
  Neutral: '#929797',
  White: '#ffffff',

  // Extra
  Yellow: '#eaaa3C',
};

const Primitive: ColorDefinition['Primitive'] = {
  Primary: ClassicPalette.Green,
  PrimaryTint: ClassicPalette.GreenHighlight,
  PrimaryContrast: ClassicPalette.White,

  Secondary: ClassicPalette.Red,
  SecondaryTint: ClassicPalette.RedLight,
  SecondaryContrast: ClassicPalette.White,

  Accent: ClassicPalette.Green,
  AccentTint: ClassicPalette.GreenHighlight,
  AccentContrast: ClassicPalette.White,

  Gradient: ClassicPalette.Green,
  GradientTint: ClassicPalette.GreenHighlight,
  GradientContrast: ClassicPalette.White,
};

const TextColors: ColorDefinition['TextColors'] = {
  HeadingText: ClassicPalette.Black,
  BodyText: ClassicPalette.Dark,
  MutedText: ClassicPalette.Neutral,
  HighlightedText: ClassicPalette.Black,
  LinkText: ClassicPalette.Green,
  ErrorText: ClassicPalette.Red,
  DisabledText: ClassicPalette.Lighter,
};

const Surface: ColorDefinition['Surface'] = {
  Base: {
    Background: ClassicPalette.White,
    Foreground: ClassicPalette.Darker,
    Dimmed: ClassicPalette.Lighter,
  },
  Nested: {
    Background: '#f0f3f3',
    Foreground: ClassicPalette.Dark,
    Dimmed: ClassicPalette.Lighter,
  },
  Disabled: {
    Background: ClassicPalette.Light,
    Foreground: ClassicPalette.Neutral,
    Dimmed: ClassicPalette.Lighter,
  },
  Floating: {
    Background: ClassicPalette.White,
    Foreground: ClassicPalette.Darker,
    Dimmed: ClassicPalette.Lighter,
  },
};

const Signal: ColorDefinition['Signal'] = {
  Success: ClassicPalette.Green,
  SuccessContrast: ClassicPalette.White,

  Info: ClassicPalette.Green,
  InfoContrast: ClassicPalette.White,

  Attention: ClassicPalette.Yellow,
  AttentionContrast: ClassicPalette.White,

  Danger: ClassicPalette.Red,
  DangerContrast: ClassicPalette.White,
};

const CornerRadius: ColorDefinition['CornerRadius'] = {
  Small: '4px',
  Base: '12px',
  Large: '32px',
};

const Elevation: ColorDefinition['Elevation'] = {
  Level1: '0 3px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level2: '0 4px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level3: '0 8px 18px var(--shadow-color, rgba(0, 0, 0, 0.15)), 0 3px 6px var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: ColorDefinition['General'] = {
  Bonus: ClassicPalette.Green,
  BonusContrast: ClassicPalette.White,
};

const Layout: ColorDefinition['Layout'] = {
  Background: ClassicPalette.Silk,
  BrandBorder: ClassicPalette.Green,
  BrowserTheme: ClassicPalette.Silk,
  LoadingColor: ClassicPalette.Green,
  Overlay: ClassicPalette.White + 'CC',
  Sidebar: {
    Background: ClassicPalette.Darker,
    Foreground: ClassicPalette.Lighter,
  },
};

const Hero: ColorDefinition['Hero'] = {
  Heading: ClassicPalette.White,
  Text: ClassicPalette.White,
  TextHighlight: Primitive.Secondary,

  Button: {
    Solid: Primitive.Secondary,
    Rest: Primitive.Secondary,
    Hover: Primitive.SecondaryTint,
    Active: Primitive.SecondaryTint,
    Text: Primitive.SecondaryContrast,
  },
};

export const Color: ColorDefinition = {
  TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,
};
