import { Color } from '@pafcloud/style';

// The menu on classic theme is inverted this is why foreground and background color has been swapped
export const Colors = {
  Text: Color.Surface.Base.Dimmed,
  TextHover: Color.Surface.Base.Background,
  TextActive: Color.Surface.Base.Background,
  TextShadow: undefined,

  Icon: 'currentColor',
  IconActive: Color.Primitive.Primary,

  BackgroundHover: 'rgba(0,0,0,0.2)',
  BackgroundActive: 'rgba(0,0,0,0.2)',

  ItemDivider: '1px solid rgba(0,0,0,0.08)',
  ItemActiveShadow: `inset 4px 0 0 ${Color.Primitive.Primary}`,

  SubMenuBackground: 'rgba(0,0,0,0.2)',
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: undefined,
  Weight: 'bold',
  WeightHover: 'bold',
  WeightActive: 'bold',
} as const;
